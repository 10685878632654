const React = require("react");

const STATUS_ACTIVE = 0;
const STATUS_INACTIVE = 1; //STATUS_INACTIVE = Desmobilizado
const STATUS_REJECTED = 2;
const STATUS_ANALYZE = 3;
const STATUS_DRAFT = 4;
const STATUS_ANALYZE_DESMOBILIZED = 5;
const STATUS_DESMOBILIZED = 6;

const STATUS_DOC_ACTIVE = 0;
const STATUS_DOC_INACTIVE = 1;
const STATUS_DOC_REJECTED = 2;
const STATUS_DOC_NEW = 3;
const STATUS_DOC_TO_ARCHIVE = 4;

const STATUS_REQUIRED_DOC_CONTRACTED = 5;
const STATUS_REQUIRED_DOC_CONTRACTED_APPROVED = 6;
const STATUS_REQUIRED_DOC_CONTRACTED_REPROVED = 7;
const STATUS_REQUIRED_INACTIVE_DOC_CONTRACTED = 8;

const CLASSIFICATION_DOC_NORMAL = 0;
const CLASSIFICATION_DOC_ANALYZE = 1;
const CLASSIFICATION_DOC_PENDING = 2;
const CLASSIFICATION_DOC_EXPIRING = 3;

const TYPE_CONTRACTED_CONTRACTED = 0;
const TYPE_CONTRACTED_SUBCONTRACTED = 1;
const TYPE_CONTRACTED_SUBSUBCONTRACTED = 2;

const STATUS_CONTRACTED_ACTIVE = 0;
const STATUS_CONTRACTED_ANALYZE = 1;
const STATUS_CONTRACTED_INACTIVE = 2;
const STATUS_CONTRACTED_REPROVED = 3;

const TYPE_WORKER = 0;
const TYPE_ACTIVE = 1;

const CLASSIFICATION_WORKER_NORMAL = 0;
const CLASSIFICATION_WORKER_EXPIRING = 1;
const CLASSIFICATION_WORKER_PENDING = 2;

const CLASSIFICATION_CONTRACTED_NORMAL = 0;
const CLASSIFICATION_CONTRACTED_EXPIRING = 3;
const CLASSIFICATION_CONTRACTED_PENDING = 2;

const STATUS_REGISTER_ACTIVE = 0;
const STATUS_REGISTER_INACTIVE = 1;

const STATUS_LOGIN_ACTIVE = 0;
const STATUS_LOGIN_INACTIVE = 1;
const STATUS_LOGIN_EXPIRE = 2;
const STATUS_LOGIN_PASSWORD_EXPIRE = 3;
const STATUS_LOGIN_ERR_PASSWORD = 4;
const STATUS_LOGIN_WAITING_PASS = 5;

const SUPERVISION_NORMAL = 0;
const SUPERVISION_PENDING = 1;

const STATUS_REGISTER_ACCESS_ACTIVE = 0;
const STATUS_REGISTER_ACCESS_WAITING_APPROVE = 1;
const STATUS_REGISTER_ACCESS_APPROVED = 2;
const STATUS_REGISTER_ACCESS_DISAPPROVED = 3;
const STATUS_REGISTER_ACCESS_DELETED = 4;

const REGISTER_ACCESS_IN = 0;
const REGISTER_ACCESS_OUT = 1;

const intl = require("react-intl-universal");
const { getUserConfigs } = require("./session");
const PERMISSION_DOCUMENT_QRCODE_ALL = 0;

const STATUS_QRCODE_ACTIVE = 0;
const STATUS_QRCODE_INACTIVE = 1;

const NOTIFICATION_TYPE_GENERAL = 1;
const NOTIFICATION_TYPE_HOLDING = 2;
const NOTIFICATION_TYPE_COMPANIE = 3;
const NOTIFICATION_TYPE_CONTRACTED = 4;
const NOTIFICATION_TYPE_SUBCONTRACTED = 5;

const CONTROLLER_WEEK_SUNDAY = 0; // Domingo
const CONTROLLER_WEEK_MONDAY = 1; // Segunda-feira
const CONTROLLER_WEEK_TUESDAY = 2; // Terça-feira
const CONTROLLER_WEEK_WEDNESDAY = 3; // Quarta-feira
const CONTROLLER_WEEK_THURSDAY = 4; // Quinta-feira
const CONTROLLER_WEEK_FRIDAY = 5; // Sexta-feira
const CONTROLLER_WEEK_SATURDAY = 6; // Sábado

const SSO_DEFAULT = 0;
const SSO_COMPACT = 1;

const getLoginSso = function () {
  const userConfigs = getUserConfigs();
  if (userConfigs) {
    if (userConfigs.loginSso === SSO_COMPACT) {
      return "true";
    }

    return "false";
  }
};

const getControllDaysWeek = function (daysWeek) {
  if (daysWeek === 0) {
    return translate("Sunday");
  }
  if (daysWeek === 1) {
    return translate("Monday");
  }
  if (daysWeek === 2) {
    return translate("Tuesday");
  }
  if (daysWeek === 3) {
    return translate("Wednesday");
  }
  if (daysWeek === 4) {
    return translate("Thursday");
  }
  if (daysWeek === 5) {
    return translate("Friday");
  }
  if (daysWeek === 6) {
    return translate("Saturday");
  }
};

const getLabelTypeNotification = function (type) {
  if (type === 1) {
    return translate("all");
  }
  if (type === 2) {
    return translate("holding");
  }
  if (type === 3) {
    return translate("development");
  }
  if (type === 4) {
    return translate("hired");
  }
  if (type === 5) {
    return translate("subcontracted");
  }

  return "";
};

// const mapStatusUser = new Map();
// mapStatusUser.set(STATUS_LOGIN_ACTIVE, 'Ativo');
// mapStatusUser.set(STATUS_LOGIN_INACTIVE, 'Inativo');
// mapStatusUser.set(STATUS_LOGIN_EXPIRE, 'Expirado por inatividade');
// mapStatusUser.set(STATUS_LOGIN_PASSWORD_EXPIRE, 'Senha expirada');
// mapStatusUser.set(STATUS_LOGIN_ERR_PASSWORD, 'Bloqueado por tentativas')
// mapStatusUser.set(STATUS_LOGIN_WAITING_PASS, 'Aguardando senha');

const getLabelStatusWorker = function (status) {
  if (status === 0) {
    return translate("able");
  }
  if (status === 1) {
    return translate("demobilized");
  }
  if (status === 2) {
    return translate("disapproved");
  }
  if (status === 3) {
    return translate("underAnalysis");
  }
  if (status === 4) {
    return translate("drafts");
  }
  if (status === 5) {
    return translate("demobilizeInAnalysis");
  }
  return "";
};

const getLabelStatusContracted = function (status) {
  if (status === 0) {
    return translate("able");
  }
  if (status === 1) {
    return translate("underAnalysis");
  }
  if (status === 2) {
    return translate("inactive");
  }
  if (status === 3) {
    return translate("disapproved");
  }
  return "";
};

const getLastStatusContracted = function (status) {
  if (status === 0) {
    return translate("able");
  }
  if (status === 1) {
    return translate("waitingForApproval");
  }
  if (status === 2) {
    return translate("inactive");
  }
  if (status === 3) {
    return translate("disapproved");
  }
  return "";
};

const getColorStatusContracted = function (status) {
  if (status === 0) {
    return "green";
  }
  if (status === 1) {
    return "yellow";
  }
  if (status === 2) {
    return "grey";
  }
  if (status === 3) {
    return "red";
  }
  return "";
};

/**
 * Mostra msg de como é o padrão de senha
 */
const showMsgDefaul = () => {
  let msgDefaultPass = [];

  msgDefaultPass.push(translate("thePasswordMustHaveLeast"));
  msgDefaultPass.push(translate("oneCapitalLetter"));
  msgDefaultPass.push(translate("oneSpecialCharacter"));
  msgDefaultPass.push(translate("oneNumber"));
  msgDefaultPass.push(translate("12Digits"));
  msgDefaultPass.push(
    translate("thePasswordCannotContainFirst,lastOrCompanyNames.")
  );

  return msgDefaultPass;
};

const getColorStatusWorker = function (status) {
  if (status === 0) {
    return "green";
  }
  if (status === 1) {
    return "gray";
  }
  if (status === 2) {
    return "red";
  }
  if (status === 3) {
    return "blue";
  }
  if (status === 4) {
    return "gray";
  }
  if (status === 5) {
    return "orange";
  }
  return "black";
};

const getColorStatusUsers = function (status) {
  if (status === 0) {
    return "green";
  }
  if (status === 1) {
    return "gray";
  }
  if (status === 2) {
    return "red";
  }
  if (status === 3) {
    return "yellow";
  }
  if (status === 4) {
    return "orangeRed";
  }
  if (status === 5) {
    return "orange";
  }
  return "black";
};

const getLabelStatusDocument = function (status) {
  if ((status === STATUS_DOC_ACTIVE) || (status === STATUS_REQUIRED_DOC_CONTRACTED_APPROVED)) {
    return translate("active");
  }
  if ((status === STATUS_DOC_INACTIVE) || (status === STATUS_REQUIRED_INACTIVE_DOC_CONTRACTED)) {
    return translate("inactive");
  }
  if ((status === STATUS_DOC_REJECTED) || status === STATUS_REQUIRED_DOC_CONTRACTED_REPROVED) {
    return translate("rejected");
  }
  if ((status === STATUS_DOC_NEW) || (status === STATUS_REQUIRED_DOC_CONTRACTED)) {
    return translate("newRegister");
  }
  if (status === STATUS_DOC_TO_ARCHIVE) {
    return translate("UnderAnalysisToDelete");
  }
};

const getLabelClassificationDocument = (classification) => {
  if (classification === CLASSIFICATION_DOC_ANALYZE) {
    return translate("underAnalysis");
  }
  if (classification === CLASSIFICATION_DOC_EXPIRING) {
    return translate("winning");
  }
  if (classification === CLASSIFICATION_DOC_NORMAL) {
    return translate("Normal");
  }
  if (classification === CLASSIFICATION_DOC_PENDING) {
    return translate("pendencies");
  }
};

const getLabelClassificationWorker = (classification, language) => {
  if (classification === CLASSIFICATION_WORKER_NORMAL) {
    return translate("normal", language);
  }
  if (classification === CLASSIFICATION_WORKER_EXPIRING) {
    return translate("winning", language);
  }
  if (classification === CLASSIFICATION_WORKER_PENDING) {
    return translate("pendencie", language);
  }
};

const getLabelClassificationContracted = (classification) => {
  if (classification === CLASSIFICATION_CONTRACTED_NORMAL) {
    return translate("Normal");
  }
  if (classification === CLASSIFICATION_CONTRACTED_PENDING) {
    return translate("pendencie");
  }
  if (classification === CLASSIFICATION_CONTRACTED_EXPIRING) {
    return translate("winning");
  }
};

/**
 * Retorna cor conforma classificacao do contratada
 * @param {*} classification
 * @param {*} showBlack = caso nao queira mostrar o preto / listagem contratadas
 * @returns
 */
const getColorClassificationContracted = (classification, showBlack = true) => {
  if (showBlack) {
    if (classification === CLASSIFICATION_CONTRACTED_NORMAL) {
      return "black";
    }
  }
  if (classification === CLASSIFICATION_CONTRACTED_EXPIRING) {
    return "orange";
  }
  if (classification === CLASSIFICATION_CONTRACTED_PENDING) {
    return "red";
  }
};

const getColorClassificationDocument = (classification) => {
  if (classification === CLASSIFICATION_DOC_NORMAL) return "black";
  if (classification === CLASSIFICATION_DOC_EXPIRING) return "orange";
  if (classification === CLASSIFICATION_DOC_PENDING) return "red";
};

const getLabelContracteds = (type) => {
  if (type === TYPE_CONTRACTED_CONTRACTED) {
    return translate("hired");
  }
  if (type === TYPE_CONTRACTED_SUBCONTRACTED) {
    return translate("subcontracted");
  }
  if (type === TYPE_CONTRACTED_SUBSUBCONTRACTED) {
    return translate("subcontracting");
  }
};

const validarEmail = function (email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const removerSessionStorage = function () {
  sessionStorage.removeItem("worker");
  sessionStorage.removeItem("ativos");
  sessionStorage.removeItem("usuario");
  sessionStorage.removeItem("empresa");
  sessionStorage.removeItem("relatorio");
  sessionStorage.removeItem("contratada");
  sessionStorage.removeItem("tipoAtuacao");
  sessionStorage.removeItem("supervisoes");
  sessionStorage.removeItem("empreendimento");
  sessionStorage.removeItem("unidadeOperacional");
  sessionStorage.removeItem("jobs");
  sessionStorage.removeItem("tipoAtivo");
};

const getOptionsStatusRegister = () => {
  return [
    {
      value: STATUS_REGISTER_ACTIVE,
      label: translate("active"),
    },
    {
      value: STATUS_REGISTER_INACTIVE,
      label: translate("inactive"),
    },
  ];
};

/**
 * Retorno  o label referente ao status do login informado
 * @param {*} status
 */
const getLabelStatusLogin = (status) => {
  if (status === 0) return translate("aCTIVE");
  if (status === 1) return translate("inactive");
  if (status === 2) return translate("expiredDueInactivity");
  if (status === 3) return translate("passwordExpired");
  if (status === 4) return translate("blockedByAttempts");
  if (status === 5) return translate("awaitingPassword");

  return mapStatusUser.get(status);
};

const translate = (key) => {
  const value = intl.get(key);
  if (value) return value;
  return key;
};

const getLabelNoData = () => {
  return translate("noDataTable");
};

const getGender = (key) => {
  if (key === "Masculino") {
    return "male";
  }
  if (key === "Feminino") {
    return "feminine";
  }
  return "male";
};

const getStatusUser = (status, language) => {
  if (status === STATUS_LOGIN_ACTIVE) {
    return translate("aCTIVE", language);
  }
};

const mapStatusUser = new Map();
mapStatusUser.set(STATUS_LOGIN_ACTIVE, translate("aCTIVE"));
mapStatusUser.set(STATUS_LOGIN_INACTIVE, translate("inactive"));
mapStatusUser.set(STATUS_LOGIN_EXPIRE, translate("expiredDueInactivity"));
mapStatusUser.set(STATUS_LOGIN_PASSWORD_EXPIRE, translate("passwordExpired"));
mapStatusUser.set(STATUS_LOGIN_ERR_PASSWORD, translate("blockedByAttempts"));
mapStatusUser.set(STATUS_LOGIN_WAITING_PASS, translate("awaitingPassword"));

/**
 * Retorna quantidade do documento (qrCode)
 * @param {*} bytes
 * @returns
 */
const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 byte";
  }
  return bytes;
};

const getLanguages = () => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const languages = [
    {
      value: "pt-BR",
      image: "assets/img/brazil.png",
      name: "brazil",
    },
    {
      value: "en-US",
      image: "assets/img/usa.png",
      name: "usa",
    },
    {
      value: "it-IT",
      image: "assets/img/italy.png",
      name: "italy",
    },
    {
      value: "es-ES",
      image: "assets/img/spain.png",
      name: "spain",
    },
  ];
  return languages.map((language) => {
    return {
      value: language.value,
      label: (
        <div style={style}>
          <img width="30px" src={language.image} alt={language.name} />
        </div>
      ),
    };
  });
};

const formattedCpf = (cpf) => {
  if (cpf) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
  return "";
};

const filterSelect = (options, value) => {
  if (!options) {
    return "";
  } else {
    let optionValue = options.filter((option) => option.value === value);
    return optionValue;
  }
};

module.exports = {
  getLabelStatusWorker,
  getColorStatusWorker,
  getLabelStatusContracted,
  getLabelStatusDocument,
  validarEmail,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_ANALYZE,
  STATUS_REJECTED,
  STATUS_DRAFT,
  STATUS_DESMOBILIZED,
  getLabelContracteds,
  STATUS_DOC_ACTIVE,
  STATUS_DOC_INACTIVE,
  STATUS_DOC_REJECTED,
  STATUS_DOC_NEW,
  STATUS_DOC_TO_ARCHIVE,
  STATUS_LOGIN_INACTIVE,
  STATUS_REGISTER_ACTIVE,
  STATUS_REGISTER_INACTIVE,
  getOptionsStatusRegister,
  TYPE_WORKER,
  TYPE_ACTIVE,
  STATUS_LOGIN_ACTIVE,
  STATUS_LOGIN_WAITING_PASS,
  getColorStatusContracted,
  STATUS_LOGIN_EXPIRE,
  STATUS_LOGIN_PASSWORD_EXPIRE,
  STATUS_LOGIN_ERR_PASSWORD,
  CLASSIFICATION_DOC_NORMAL,
  CLASSIFICATION_DOC_ANALYZE,
  CLASSIFICATION_DOC_PENDING,
  CLASSIFICATION_DOC_EXPIRING,
  CLASSIFICATION_WORKER_NORMAL,
  CLASSIFICATION_WORKER_EXPIRING,
  CLASSIFICATION_WORKER_PENDING,
  removerSessionStorage,
  getLabelStatusLogin,
  getLabelClassificationDocument,
  getLabelClassificationWorker,
  STATUS_ANALYZE_DESMOBILIZED,
  showMsgDefaul,
  SUPERVISION_NORMAL,
  SUPERVISION_PENDING,
  STATUS_REGISTER_ACCESS_ACTIVE,
  STATUS_REGISTER_ACCESS_APPROVED,
  STATUS_REGISTER_ACCESS_DELETED,
  STATUS_REGISTER_ACCESS_DISAPPROVED,
  STATUS_REGISTER_ACCESS_WAITING_APPROVE,
  formatSizeUnits,
  REGISTER_ACCESS_IN,
  REGISTER_ACCESS_OUT,
  TYPE_CONTRACTED_CONTRACTED,
  TYPE_CONTRACTED_SUBCONTRACTED,
  TYPE_CONTRACTED_SUBSUBCONTRACTED,
  STATUS_CONTRACTED_ACTIVE,
  STATUS_CONTRACTED_ANALYZE,
  STATUS_CONTRACTED_INACTIVE,
  STATUS_CONTRACTED_REPROVED,
  getColorStatusUsers,
  getLastStatusContracted,
  PERMISSION_DOCUMENT_QRCODE_ALL,
  STATUS_QRCODE_ACTIVE,
  STATUS_QRCODE_INACTIVE,
  NOTIFICATION_TYPE_GENERAL,
  NOTIFICATION_TYPE_HOLDING,
  NOTIFICATION_TYPE_COMPANIE,
  NOTIFICATION_TYPE_CONTRACTED,
  NOTIFICATION_TYPE_SUBCONTRACTED,
  getLabelTypeNotification,
  CONTROLLER_WEEK_SUNDAY,
  CONTROLLER_WEEK_MONDAY,
  CONTROLLER_WEEK_TUESDAY,
  CONTROLLER_WEEK_WEDNESDAY,
  CONTROLLER_WEEK_THURSDAY,
  CONTROLLER_WEEK_FRIDAY,
  CONTROLLER_WEEK_SATURDAY,
  SSO_DEFAULT,
  SSO_COMPACT,
  getControllDaysWeek,
  translate,
  getLabelNoData,
  getLabelClassificationContracted,
  getColorClassificationContracted,
  getGender,
  getStatusUser,
  getLanguages,
  getLoginSso,
  formattedCpf,
  filterSelect,
  getColorClassificationDocument,
  STATUS_REQUIRED_DOC_CONTRACTED,
  STATUS_REQUIRED_DOC_CONTRACTED_APPROVED,
  STATUS_REQUIRED_DOC_CONTRACTED_REPROVED,
  STATUS_REQUIRED_INACTIVE_DOC_CONTRACTED
};
